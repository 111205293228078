import { Image } from '@/theme/components/Image'
import QuantityInput from '@/theme/components/QuantityCounter/QuantityInput'
import BogoQuantityInput from '@/theme/components/QuantityCounter/BogoQuantityInput'
import { Currency } from '@/services/helpers/currency'
import useCartContext from '../useCartContext'
import './cart-tile.scss'

const CartProductTitle = ({ title }) => {
  return <div className="cart__item-name">{title}</div>
}
const CartProductPrice = ({ price, discountPrice = 0 }) => {
  return (
    <div className={'cart-product-price'}>
      {discountPrice > 0 && (
        <span className={'discount'}>{Currency({ money: discountPrice })}</span>
      )}
      <span className={'current-price'}> {Currency({ money: price })} </span>
    </div>
  )
}

export const CartTile = (props) => {
  const { checkout = false } = props
  const [cartState, cartDispatch, { removeItem, updateCartItem }] =
    useCartContext()
  const { items } = cartState
  return (
    <>
      {items.length > 0 &&
        items.map((items, index) => {
          const {
            uid,
            configured_variant,
            product,
            configurable_options,
            product: { name, stock_status, bogo },
            prices: { price },
            quantity,
          } = items
          const showCounter = price.value == 0 || checkout ? false : true
          const inStock = stock_status != 'OUT_OF_STOCK'
          const image = configured_variant?.thumbnail || product?.image || {}
          const variants = (configurable_options || []).reduce((ac, a) => {
            return ac + a.value_label
          }, ' - ')

          return (
            <div className={'cart-tile'} key={index}>
              <Image {...image} />
              <div className={'cart-content'}>
                {showCounter && (
                  <div
                    onClick={() => {
                      removeItem(uid)
                    }}
                    className="delete"
                  >
                    {' '}
                  </div>
                )}
                <CartProductTitle title={`${name}${variants}`} />
                <CartProductPrice price={price.value} />
                {checkout && (
                  <div>
                    <label className="label">Qty</label> {quantity}
                  </div>
                )}
                {showCounter && (
                  <>
                    {bogo != 1 ? (
                      <QuantityInput
                        quantity={quantity}
                        setQuantity={(quantity) => {
                          updateCartItem({
                            cart_item_uid: uid,
                            quantity,
                            price: price.value,
                          })
                        }}
                        disable={cartState.loading || !inStock}
                      />
                    ) : (
                      <BogoQuantityInput
                        quantity={quantity}
                        setQuantity={(quantity) => {
                          updateCartItem({
                            cart_item_uid: uid,
                            quantity,
                            price: price.value,
                          })
                        }}
                        disable={cartState.loading || !inStock}
                      />
                    )}
                  </>
                )}
                {!inStock && (
                  <div className="cart-out-of-stock">
                    Current item is out of stock
                  </div>
                )}
              </div>
            </div>
          )
        })}
    </>
  )
}
