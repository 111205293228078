import './whatsapp.scss'

export const WhatsappIcon = ()=>{
    return (
        <>
            <a href="https://api.whatsapp.com/send?phone=918657903380&text=Hello.." className="float" target="_blank">
                <img width='45px' src='/whatsapp.png' />
                
            </a>
        </>
    )
}