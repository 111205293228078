import FooterWrapper from './FooterWrapper';
import FooterBottom from './FooterBottom';
import './footer.scss';
export default function Footer(props) {
  return (
    <footer className='page-footer'>
      <FooterWrapper />
      <FooterBottom />
    </footer>
  );
}
