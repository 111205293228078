export const mobileMenu = [
    {
        "url_path": "shop/category/skin",
        "name": "Skin",
        "children": []
    },
    {
        "url_path": "shop/category/hair",
        "name": "Hair",
        "children": []
    },
    {
        "url_path": "shop/category/bath-body",
        "name": "Bath & Body",
        "children": []
    },
    {
        "url_path": "shop/category/spa",
        "name": "Spa",
        "children": []
    },
    {
        "name": "By Concern",
        "url_path": "shop/concern",
        "is_anchor": 1,
        "children": [
            {
                "url_path": "shop/concern/face-body",
                "url_suffix": "",
                "name": "Face & Body",
                "children": [
                    {
                        "url_path": "shop/concern/face-body/acne-pimple-breakouts",
                        "url_suffix": "",
                        "name": "Acne & Pimple-Breakouts",
                        "children": []
                    },
                    {
                        "url_path": "shop/concern/face-body/dull-dry-skin",
                        "url_suffix": "",
                        "name": "Dull & Dry Skin",
                        "children": []
                    },
                    {
                        "url_path": "shop/concern/face-body/blackheads-rough-skin",
                        "url_suffix": "",
                        "name": "Blackheads & Rough Skin",
                        "children": []
                    },
                    {
                        "url_path": "shop/concern/face-body/pigmentation",
                        "url_suffix": "",
                        "name": "Pigmentation",
                        "children": []
                    },
                    {
                        "url_path": "shop/concern/face-body/ageing",
                        "url_suffix": "",
                        "name": "Ageing",
                        "children": []
                    }
                ]
            },
            {
                "url_path": "shop/concern/hair",
                "url_suffix": "",
                "name": "Hair",
                "children": [
                    {
                        "url_path": "shop/concern/hair/hairfall-control",
                        "url_suffix": "",
                        "name": "Hairfall Control",
                        "children": []
                    },
                    {
                        "url_path": "shop/concern/hair/dull-dry-hair",
                        "url_suffix": "",
                        "name": "Dull & Dry Hair",
                        "children": []
                    },
                    {
                        "url_path": "shop/concern/hair/dandruff-itchy-scalp",
                        "url_suffix": "",
                        "name": "Dandruff & Itchy Scalp",
                        "children": []
                    }
                ]
            }
        ]
    },
    {
        "name": "Offers",
        "url_path": "offers",
        "is_anchor": 1,
        "children": []
    },
    {
        "name": "Gift Card",
        "url_path": "gift-card",
        "is_anchor": 1,
        "children": []
    },
    {
        "name": "moha: TV",
        "url_path": "tv",
        "is_anchor": 0,
        "children": []
    },
    {
        "name": "New Arrival",
        "url_path": "new-arrival",
        "is_anchor": 1,
        "children": []
    }
]