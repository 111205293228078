import React, { useState } from 'react'
import './checkout.scss'
import { CheckoutMessageTile } from '@/theme/components/Checkout/CheckoutMessageTile'
import { CheckoutShipping } from '@/theme/components/Checkout/CheckoutShipping'
import { Accordian } from '@/theme/components/Accordian'
import { CartTile } from '@/theme/components/CartWrapper/CartTile'
import { CartPaymentTile } from '@/theme/components/CartWrapper/CartPaymentTile'
import { Currency } from '@/services/helpers/currency'
import { useAppContext } from '@/core/App/AppContext'
import useCartContext from '../CartWrapper/useCartContext'
import { checkFreeShipping } from './checkFreeShipping'
import ModalComponent from '../ModalComponent'
import { CheckoutGlowPoint } from './CheckoutGlowPoint'
import useAuthContext from '../Contexts/AuthContext'
import Form from '../Form'
import { useForm } from 'react-hook-form'
import Button, { Buttons } from '../Button'
import { graphqlWebClient } from '@/theme/lib/graphqlClient'
import { updateCustomerNote } from 'src/graphql/mutations/customermutation'
import { CheckoutGiftCard } from './CheckoutGiftCard'
import { CheckoutCoupon } from './CheckoutCoupon'
import { EmailCart } from '../EmailCart'

export const CashBackText = () => {
  const [{ mitter }] = useAppContext()
  const [cartState] = useCartContext()
  const { prices, mstRewardPoints } = cartState && cartState
  const [auth] = useAuthContext()
  const earnedGlowPoint = mstRewardPoints?.earn_points
  const earnText = !auth.isAuthenticated ? 'earn' : 'Earn'
  return (
    <p className="cashback">
      {!auth.isAuthenticated && (
        <span
          className="pointer"
          onClick={() => {
            mitter.emit('TOGGLE_LOGIN_MODEL')
            mitter.emit('MiniCartToggle')
          }}
        >
          <span className="primary-color"> Login</span> to{' '}
        </span>
      )}{' '}
      {earnText} moha: glowpoints{' '}
      <span className="primary-color">{earnedGlowPoint}</span> on order
      delivery.
    </p>
  )
}
export const Checkout = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [{ mitter, isMobile }] = useAppContext()
  const [cartState, cartDispatch, { getQuoteId, reloadCart, removeCoupon }] =
    useCartContext()
  const [auth] = useAuthContext()
  const [open, openModal] = useState(false)
  const { prices, extentionAttr, order_comment, hasOnlyGiftCard } =
    cartState && cartState
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cartnote: cartState.order_comment,
    },
  })
  const couponOnCart =
    cartState?.applied_coupons && cartState?.applied_coupons[0]?.code

  if (cartState.items.length == 0) {
    return null
  }

  const onSubmit = async ({ cartnote }) => {
    const quoteId = await getQuoteId()
    try {
      const { updateCustomerNote: cartData } = await graphqlWebClient.request(
        updateCustomerNote({ cart_id: quoteId, cartnote })
      )
      if (!cartData.order_comment) {
        alert('Cart note removed')
      } else {
        alert('Cart note added')
      }
      reloadCart(cartData)
    } catch (err) {}
  }

  return (
    <div className="container-width checkout-wrapper">
      <div className="mttop">
        <CheckoutMessageTile type="large">
          <div>
            Please use a digital payment method &amp; help us ensure contactless
            delivery for your safety.
          </div>
        </CheckoutMessageTile>
        <div className="mobile-main-new-data">
          Login to earn moha: <br />
          glowpoints24 on order delivery
        </div>
        <div className="checkout-content">
          <CheckoutShipping />
          <div className="checkout-items">
            <CartPaymentTile />
            <Accordian
              className="cart-items"
              index={0}
              selectedIndex={selectedIndex}
              onClick={setSelectedIndex}
              title={
                <>
                  items in the cart
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                      mitter.emit('MiniCartToggle')
                    }}
                    className="edit-order"
                  >
                    Edit
                  </div>
                </>
              }
            >
              <CartTile checkout={true} />
            </Accordian>
            {auth.isAuthenticated && !hasOnlyGiftCard && (
              <Accordian
                className="glow-points"
                index={1}
                selectedIndex={selectedIndex}
                onClick={setSelectedIndex}
                title={'moha glow points'}
              >
                <CheckoutGlowPoint />
              </Accordian>
            )}
            {!hasOnlyGiftCard && (
              <Accordian
                className="gift-card"
                index={auth.isAuthenticated && !hasOnlyGiftCard ? 2 : 1}
                selectedIndex={selectedIndex}
                onClick={setSelectedIndex}
                title={'gift cards'}
              >
                <CheckoutGiftCard />
              </Accordian>
            )}

            {!hasOnlyGiftCard ? <CheckoutCoupon /> : null}

            <div className="cart-note">
              {!order_comment ? (
                <div className="note-input">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Form.TextareaField
                      rows={2}
                      label="Add Cart Note"
                      {...register('cartnote')}
                    ></Form.TextareaField>
                    <Buttons>
                      <Button className="cart-note">Add</Button>
                      <Button
                        onClick={() => {
                          openModal(true)
                        }}
                        className={'checkout'}
                        variant="primary"
                      >
                        Email Cart
                      </Button>
                    </Buttons>
                  </form>
                </div>
              ) : (
                <div className="cart-note-text">
                  Cart Note : {order_comment}
                  <i
                    className="fa fa-close"
                    onClick={() => onSubmit({ cartnote: '' })}
                  ></i>
                </div>
              )}
            </div>
            {/* <div className="email-cart-button-container">
            <Buttons className='email-cart-button'>
              <Button
                onClick={() => {
                  openModal(true);
                }}
                className={"checkout"}
                variant="primary"
              >
                Email Cart
              </Button>
            </Buttons>
          </div> */}
            <CashBackText />
            {!isMobile && (
              <CheckoutMessageTile type="small" color="secondary">
                <div className="prepaid-container">
                  <div className="prepaid">prepaid payable</div>
                  <div className="prepaid-amount">
                    {Currency({
                      money: checkFreeShipping(extentionAttr, prices) || 0,
                    })}
                  </div>
                </div>
              </CheckoutMessageTile>
            )}
          </div>
        </div>
        <ModalComponent
          isOpen={open}
          close={(data) => openModal(data)}
          title={'Email Cart'}
        >
          <EmailCart close={(data) => openModal(data)} />
        </ModalComponent>
      </div>
    </div>
  )
}
