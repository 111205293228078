import { mediaUrl } from 'src/server/utils'
import './footer-bottom.scss'
export default function FooterBottom(props) {
  return (
    <div className="footer-bottom">
      {/* <div className="footer-bottom-inner footer-middle-container">
        <small className="copyright">
          <span>
            Copyright © 2013-present Magento, Inc. All rights reserved.
          </span>
        </small>
      </div> */}

      {/* <div className="bottom-icons">
        <div className="payment-icons">
          <ul className="payment-options footer-module">
            <li className="pay-title">Payment Secured by</li>
            <li className="pay-logo pay-all-logo">
              <img
                width="417"
                height="37"
                className="alignright"
                src={mediaUrl('/media/uploads/22.png')}
                alt="onlinepaymentoptions"
              />
            </li>
          </ul>
        </div>
        <div className="shipping-icons">
          <ul className="shipping-options footer-module">
            <li className="shipping-title">Shipping Partners</li>
            <div className="shipping-logos">
              <li className="shipping-logo">
                <img
                  width="107"
                  height="37"
                  className="alignleft"
                  src={mediaUrl('/media/uploads/s3-1.png')}
                  alt="Bluedart"
                />
              </li>
              <li className="shipping-logo">
                <img
                  width="62"
                  height="37"
                  className="alignright"
                  src={mediaUrl('/media/uploads/Untitled-1.png')}
                  alt="Vicharecourier"
                />
              </li>
              <li className="shipping-logo">
                <img
                  width="138"
                  height="37"
                  className="alignright"
                  src={mediaUrl('/media/uploads/s44.png')}
                  alt="Professional"
                />
              </li>
              <li className="shipping-logo">
                <img
                  width="80"
                  height="36"
                  className="alignright"
                  src={mediaUrl('/media/uploads/s5.png')}
                  alt="MarutiCourier"
                />
              </li>
            </div>
          </ul>
        </div>
      </div> */}
    </div>
  )
}
