import { useAppContext } from '@/core/App/AppContext'
import Link from '@/core/Link'
import { useEffect } from 'react'
import { useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { mobileMenu } from './const'
import we from 'src/services/webengage'
const ifNull = (...arr) => arr.filter((a) => !!a).join(' ')

const Level0Link = ({
  children = null,
  href,
  title,
  onClick,
  index,
  selectedIndex,
}) => {
  const [{ isMobile, mitter }] = useAppContext()
  const handleClick = (e) => {
    if (isMobile) {
      children && e.preventDefault()
      selectedIndex == index ? onClick(null) : onClick(index, e)
      !children && mitter.emit('TOGGLE_NAV')
    }
    return false
  }

  useEffect(() => {
    if (index == selectedIndex) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [selectedIndex, index])

  const isParent = useMemo(() => children && 'parent', [])
  const isActive = selectedIndex == index && 'active'
  const ref = useRef<HTMLLIElement>()

  return (
    <>
      {title == 'New Arrival' ? (
        <li
          className={ifNull('level0 newarrival', isParent, isActive)}
          ref={ref}
        >
          <Link
            href={href}
            onClick={() => {
              handleClick
              we.event('Menu Clicked', {
                Name: `${title}`,
                Url: `${href}`,
              })
            }}
          >
            {title}
          </Link>
          {children}
        </li>
      ) : (
        <li className={ifNull('level0', isParent, isActive)} ref={ref}>
          <Link
            href={href}
            onClick={(ev) => {
              handleClick(ev)
              we.event('Menu Clicked', {
                Name: `${title}`,
                Url: `${href}`,
              })
            }}
          >
            {title}
          </Link>
          {children}
        </li>
      )}
    </>
  )
}

export default function Menu() {
  const [selectedIndex, selectIndex] = useState(null)
  const [{ isMobile, menu, blogCategories, mitter }] = useAppContext()

  // let flatArray = (item) => item.reduce((acc, curVal) => {
  //   return curVal?.children?.concat(acc).reverse()
  // }, []);

  // const filterMenu = menu.children.filter(d => d.children.length == 0)

  // const mobileMenu = flatArray(flatArray(menu.children))

  // filterMenu.splice(1, 0, ...mobileMenu)

  let customMenu = isMobile ? mobileMenu : menu.children
  return (
    <nav role="navigation" className="container-width">
      <ul>
        <Level0Link
          index={0}
          key={0}
          selectedIndex={selectedIndex}
          onClick={selectIndex}
          href={`/our-story`}
          title={'Our Story'}
        />
        {menu &&
          customMenu.map((data, key) => {
            // this is done coz our story and was returing type Category instead of CMS page
            return (
              <Level0Link
                index={+key + 1}
                key={key}
                selectedIndex={selectedIndex}
                onClick={(_clickIndex, ev) => {
                  console.log({ _clickIndex })
                  selectIndex(_clickIndex)
                  if (_clickIndex) {
                    ev.target.nextElementSibling.classList.add('open')
                  }
                }}
                href={`/${data.url_path}${data.url_suffix || ''}`}
                title={data.name}
              >
                {data.children.length > 0 && (
                  <ul className="submenu three">
                    {data?.children.map((level1, key) => {
                      return (
                        <li
                          key={level1.name}
                          onClick={() => {
                            mitter.emit('TOGGLE_NAV')
                          }}
                          className={clsx(
                            `level1 nav-1-${key + 1} category-item parent`
                          )}
                        >
                          <Link
                            href={`/${level1.url_path}${
                              level1.url_suffix || ''
                            }`}
                            onClick={() => {
                              we.event('Menu Clicked', {
                                Name: `${level1.name}`,
                                Url: `${`/${level1.url_path}${
                                  level1.url_suffix || ''
                                }`}`,
                              })
                            }}
                          >
                            <span>{level1.name}</span>
                          </Link>
                          {level1.children.length > 0 && (
                            <ul className="level-1">
                              {level1.children.map((level2, key) => {
                                return (
                                  <li
                                    key={key}
                                    onClick={() => {
                                      mitter.emit('TOGGLE_NAV')
                                    }}
                                    className={clsx(
                                      'level2 nav-1-1-1 category-item',
                                      {
                                        ['has-children']:
                                          level2.children.length > 0,
                                      }
                                    )}
                                  >
                                    <Link
                                      href={`/${level2.url_path}${
                                        level2.url_suffix || ''
                                      }`}
                                      onClick={() => {
                                        we.event('Menu Clicked', {
                                          Name: `${level2.name}`,
                                          Url: `${`/${level2.url_path}${
                                            level2.url_suffix || ''
                                          }`}`,
                                        })
                                      }}
                                    >
                                      <span>{level2.name}</span>
                                    </Link>
                                    {level2.children.length > 0 && (
                                      <ul className="level-2">
                                        {level2.children.map((level3, key) => {
                                          return (
                                            <li
                                              key={key}
                                              className="level3 nav-1-1-1 category-item"
                                            >
                                              <Link
                                                href={`/${level3.url_path}${
                                                  level3.url_suffix || ''
                                                }`}
                                                onClick={() => {
                                                  we.event('Menu Clicked', {
                                                    Name: `${level3.name}`,
                                                    Url: `${`/${
                                                      level3.url_path
                                                    }${
                                                      level3.url_suffix || ''
                                                    }`}`,
                                                  })
                                                }}
                                              >
                                                <span>{level3.name}</span>
                                              </Link>
                                            </li>
                                          )
                                        })}
                                      </ul>
                                    )}
                                  </li>
                                )
                              })}
                            </ul>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                )}
              </Level0Link>
            )
          })}
        <Level0Link
          index={2}
          key={2}
          selectedIndex={selectedIndex}
          onClick={selectIndex}
          href={`/blog`}
          title={'Blog'}
        >
          <ul className="submenu">
            <li
              // key={cat.url_key}
              className={clsx(`level1 nav-1-1 category-item parent`)}
            >
              <ul className="level-1">
                {blogCategories.map((level2, key) => {
                  return (
                    <li
                      key={key}
                      onClick={() => {
                        mitter.emit('TOGGLE_NAV')
                      }}
                      className={clsx('level2 nav-1-1-1 category-item')}
                    >
                      <Link
                        href={`/blog/category/${level2.url_key}${
                          level2.url_suffix || ''
                        }`}
                        onClick={() => {
                          we.event('Menu Clicked', {
                            Name: `${level2.name}`,
                            Url: `${`/${level2.url_path}${
                              level2.url_suffix || ''
                            }`}`,
                          })
                        }}
                      >
                        <span>{level2.name}</span>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </li>
          </ul>
        </Level0Link>
        {/* <div className="stand">
          <Link href="https://www.moha.co.in/bigfan" 
           onClick={()=>{
            we.event('Menu Clicked', {
              'Name':"bigfan",
              'Url': "https://www.moha.co.in/bigfan"
                    })
          }}>
              <img src="https://media.moha.co.in/media/.thumbslogo/BMFC_menu_unit-01.jpg " />
          </Link>
        </div> */}
      </ul>
    </nav>
  )
}
