import { graphqlWebClient } from "@/theme/lib/graphqlClient";
import { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import {
  createAccountOTP,
  createAccountOTPVerify,
  createCustomerAccount,
  referralGraphql,
  updateCustomer,
} from "src/graphql/mutations/customermutation";
import { loginOTP, loginOTPVerify } from "src/graphql/mutations/loginmuation";
import { deleteCookie, getCookie } from "src/services/cookieService";
import Button, { Buttons } from "../Button";
import useAuthContext from "../Contexts/AuthContext";
import Form from "../Form";
import "./login-component.scss";
import { getToken, setToken } from "@/utils/auth";
import { getCartId } from "@/utils/cart";
import { getReferralCode, removeReferralCode } from "@/utils/referral";
import { useAppContext } from "@/core/App/AppContext";
import { fbLogin, gmailLogin } from "src/services/socialLoginService";
import { ButtonLoader } from "../ButtonLoader";
import { useStore } from "react-redux";
import { customer, FBPostLogin } from "src/graphql/queries/loginQuery";
import { GoogleLogin } from 'react-google-login';
import we from "src/services/webengage";
import { gql } from "graphql-request";
import { GCAPTCH_SITE_KEY } from "src/config";
import ReCaptcha from 'react-google-recaptcha'

const EmailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i


const ResendOTP = ({ mobilenumber, isRegister, socialLogin }) => {
  const [isLocked, setIsLocked] = useState(false)
  const [remainingTime, setRemainingTime] = useState(30)
  const resend = () => {
    ; (socialLogin || isRegister) &&
      graphqlWebClient
        .request(createAccountOTP(`${mobilenumber}`))
        .then((d) => {
          alert(d.createAccountOTP.message)
          setIsLocked(true)
        })
    !socialLogin &&
      !isRegister &&
      graphqlWebClient
        .request(loginOTP(`${mobilenumber}`))
        .then((d) => {
          alert(d.loginOTP.message)
          setIsLocked(true)
        })
  }
  useEffect(() => {
    let int = null
    if (isLocked) {
      int = setInterval(() => {
        setRemainingTime(prev => prev - 1)
      }, 1000)
    }
    return () => {
      clearInterval(int)
    }
  }, [isLocked,])
  useEffect(() => {
    if (remainingTime <= 0) {
      setIsLocked(false)
      setRemainingTime(30)
    }
  }, [remainingTime])
  return (
    <div className="resend-otp">
      Not received your code?{' '}
      {isLocked ? (
        <b className="pointer" >
          {isLocked ? 'Resend in in ' + remainingTime + ' seconds' : 'Resend code'}
        </b>
      ) : (
        <b className="pointer" onClick={resend}>
          {isLocked ? 'Resend in in ' + remainingTime + ' seconds' : 'Resend code'}
        </b>
      )}
    </div>
  )
}

const SendOTP = ({ setOtpStatus, mobilenumber }) => {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mobilenumber: null,
    },
  });

  const onLoginSubmit = async (data) => {
    const {
      loginOTP: { message, status },
    } = await graphqlWebClient.request(loginOTP(`${data.mobilenumber}`));

    if (!status) {
      const {
        createAccountOTP: { message: newMessage, status, token },
      } = await graphqlWebClient.request(
        createAccountOTP(`${data.mobilenumber}`)
      );
      alert(newMessage);
      setOtpStatus({ mobilenumber: data.mobilenumber, isRegister: true });
      // setRegisterForm(true)
      // setHideContinueButton(true)
    } else {
      setOtpStatus({ mobilenumber: data.mobilenumber, isRegister: false });
      // setHideContinueButton(true)
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onLoginSubmit)}
      className="fieldset send-otp-form"
    >
      <Form.MobileNumberField
        label="Mobile Number"
        name="mobilenumber"
        control={control}
      />
      <ReCaptcha
        sitekey={GCAPTCH_SITE_KEY}
        onErrored={(event) => {
          console.log({ event })
          setIsCaptchaVerified(false)
        }}
        onExpired={(event) => {
          console.log({ event })
          setIsCaptchaVerified(false)
        }}
        onChange={(event) => {
          console.log({ event })
          setIsCaptchaVerified(true)
        }}
      />
      <br />
      <Button disabled={!isCaptchaVerified} fill>Continue</Button>
    </form>
  );
};

const FBRegisterOTP = ({ setOtpStatus, mobilenumber, setIsFbRegister, access_token, fb_verify_data }) => { // this is for FB register OTP
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mobilenumber: null,
    },
  });

  const onLoginSubmit = async (data) => {
    const {
      loginOTP: { message, status },
    } = await graphqlWebClient.request(loginOTP(`${data.mobilenumber}`));
    if (!status) {
      const {
        createAccountOTP: { message: newMessage, status, token },
      } = await graphqlWebClient.request(
        createAccountOTP(`${data.mobilenumber}`)
      );
      alert(newMessage);

      setIsFbRegister({
        fb_verify_otp: true,
        fb_button: true,
        access_token: access_token,
        type: fb_verify_data.type
      })
      setOtpStatus({ mobilenumber: data.mobilenumber, isRegister: false });
    } else {
      setIsFbRegister({
        fb_verify_otp: true,
        fb_button: true
      })
      setOtpStatus({ mobilenumber: data.mobilenumber, isRegister: false });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onLoginSubmit)}
      className="fieldset send-otp-form"
    >
      <Form.MobileNumberField
        label="Mobile Number"
        name="mobilenumber"
        control={control}
      />
      <Button fill>Continue</Button>
    </form>
  );
};


const VerifyOTP = ({
  mobilenumber,
  isRegister,
  setOtpStatus,
  close,
  fb_verify_data = null,
  setIsFbRegister }) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{
    mobilenumber: string;
    otp: string;
    firtname?: string;
    lastname?: string;
    email?: string;
    webengage_whatsapp_optin?: boolean;
  }>({
    defaultValues: {
      mobilenumber,
      otp: null,
    },
  });
  const [loading, setLoading] = useState(false);
  const [, , { reloadLogin }] = useAuthContext();

  close()
  const refCode = async () => {
    try {
      const refcode = getReferralCode();
      console.log('refcode', refcode)
      if (refcode) {
        const cart_id = getCartId();
        const {
          referralGraphql: { referral_code },
        } = await graphqlWebClient.request(
          referralGraphql({ referral_code: refcode, quote_id: cart_id })
        );
        if (referral_code) {
          alert("Referral code added to your account");
          removeReferralCode();
        }
      }

    } catch (err) {
      console.log(err);
    }
  }
  const onLoginVerify = async (data) => {
    setLoading(true);
    const { fb_verify_otp, access_token, type } = fb_verify_data;

    if (fb_verify_otp) {

      const { status, message, token } = await graphqlWebClient
        .request(loginOTPVerify(data.mobilenumber, data.otp))
        .then((d) => {
          if (d.loginOTPVerify.status) {
            return d.loginOTPVerify
          }
          else {
            return graphqlWebClient
              .request(FBPostLogin({
                access_token: access_token,
                mobilenumber: data.mobilenumber,
                otp: data.otp,
                type: type
              }))
              .then((d) => d.postSocialLogin)
          }
        })

      if (status) {
        setToken(token);
        refCode()
        reloadLogin();
      }
    } else {
      let userData = null
      let status = null
      let token = null
      let message = null
      let regRes = null
      let errors = null
      let error = null
      try {
        if (isRegister) {
          regRes = await graphqlWebClient.request(createCustomerAccount(data))
          if (regRes?.createCustomerAccount) {
            token = regRes.createCustomerAccount.token;
            message = regRes.createCustomerAccount.message;
            status = regRes.createCustomerAccount.status;
          }
        } else {
          regRes = await graphqlWebClient.request(loginOTPVerify(data.mobilenumber, data.otp))
          if (regRes?.loginOTPVerify) {
            token = regRes.loginOTPVerify.token;
            message = regRes.loginOTPVerify.message;
            status = regRes.loginOTPVerify.status;
          }
        }
        if (regRes?.errors) {
          errors = regRes?.errors
        }
      } catch (exception: any) {
        error = exception
        if (exception.response?.errors) {
          errors = exception.response?.errors
        }
        console.warn({ exception })

      } finally {
        setLoading(false);
      }
      if (errors?.length) {
        alert(errors[0].message);
        return;
      }

      if (status) {
        console.log('Inside Status');
        setToken(token);
        refCode()
        reloadLogin();
        await graphqlWebClient.request(updateCustomer, {
          input: {
            webengage_whatsapp_optin: `${data?.webengage_whatsapp_optin ? "1" : "0"}`
          }
        })
        const customerDetails = await graphqlWebClient.request(gql`{
          ${customer}
        }`);
        we.setUserAttribute('we_whatsapp_opt_in', customerDetails?.customer?.webengage_whatsapp_optin == 1 ? true : false)
        {
          isRegister ?
            // webengage.user.login('9SBOkLVMWvPX')
            we.userLogin({
              email: `${data?.email}`,
              phone: `${data?.mobilenumber}`,
              first_name: `${data?.firtname}`,
              last_name: `${data?.lastname}`,
              user_id: `${data?.email}`,
              mode: `signup`,
              modeSource: {
                source: `OTP`,
                page: ``
              }
            }, 'logged in')
            :
            we.userLogin({
              email: `${customerDetails?.customer?.email}`,
              phone: `${data?.mobilenumber}`,
              first_name: `${customerDetails?.customer?.firstname}`,
              last_name: `${customerDetails?.customer?.lastname}`,
              user_id: `${customerDetails?.customer?.email}`,
              mode: `login`,
              modeSource: {
                source: `OTP`,
                page: ``
              }
            }, 'logged in')
        }
      }
      else {
        if (message) {
          alert(message)
        } else if (errors?.length) {
          const [firstError] = errors
          alert(firstError.message)
        }
      }
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onLoginVerify)}
      className="fieldset verify-otp-form"
    >
      <input type="hidden" {...register("mobilenumber")} />

      <Form.Field label={"Mobile Number"}>
        <div className="control mobile-number-control">
          <div className="input">+{mobilenumber}</div>
          <div
            className="change input"
            onClick={(e) => {
              setOtpStatus({
                mobilenumber: false,
                isRegister: false,
              });
              setIsFbRegister({
                fb_button: false
              })
            }}
          >
            Change
          </div>
        </div>
      </Form.Field>

      {isRegister && (
        <>
          <Form.TextField
            name="firtname"
            label="First Name"
            {...register('firtname', {
              required: 'Firstname is required',
            })}
            error={errors.firtname?.message}
          ></Form.TextField>
          <Form.TextField
            name="lastname"
            label="Last Name"
            {...register('lastname', {
              required: 'Lastname is required',
            })}
            error={errors.lastname?.message}
          ></Form.TextField>
          <Form.TextField
            name="email"
            label="Email Id"
            {...register("email", {
              required: 'Email Id is required',
              pattern: {
                value: EmailRegex,
                message: 'Email Id is required',
              },
            })}
            error={errors.email?.message}
          />
        </>
      )}
      <Form.TextField
        label="OTP"
        className="otp"
        placeholder="Please enter 6 digit otp"
        {...register("otp", {
          pattern: /^[0-9]{6}$/,
          required: true,
        })}
      />
      <ResendOTP mobilenumber={mobilenumber} isRegister={isRegister} socialLogin={fb_verify_data.fb_verify_otp} />
      <div className="whatsapp-checkbox">
        <input type="checkbox" checked={true} {...register("webengage_whatsapp_optin")} />
        <span>Check this to get notified on whatsapp</span>
      </div>
      <Button fill disabled={loading}>
        {loading ? "Verifying..." : "Verify"}
      </Button>
    </form>
  );
};

export const LoginComponent = (props) => {
  const [{ isAuthenticated }] = useAuthContext();
  const [{ mitter }] = useAppContext();
  const [, , { reloadLogin }] = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [{ mobilenumber, isRegister }, setOtpStatus] = useState({
    mobilenumber: false,
    isRegister: false
  });
  const [isFbRegister, setIsFbRegister] = useState({
    fb_register: false,
    fb_access_token: null,
    fb_verify_otp: false,
    fb_button: false,
    type: null
  });
  isAuthenticated && mitter.emit("ShowToaster", {
    message: "Customer Logged In Successfully",
  });
  isAuthenticated && props.close()

  // gmail login state

  const [gmailLoading, setGmailLoading] = useState(false);

  const responseGoogle = ({ accessToken, error }) => {  // do not remove this the login component need to refactor
    if (accessToken) {
      gmailLogin(accessToken).then(async (data: any) => {
        if (data.status) {
          setToken(data.token);
          reloadLogin();
          const customerDetails = await graphqlWebClient.request(gql`{
            ${customer}
          }`);
          we.userLogin({
            email: `${customerDetails?.customer?.email}`,
            phone: `${customerDetails?.customer?.mobilenumber}`,
            first_name: `${customerDetails?.customer?.firstname}`,
            last_name: `${customerDetails?.customer?.lastname}`,
            user_id: `${customerDetails?.customer?.email}`,
            mode: `login`,
            modeSource: {
              source: `Google`,
              page: ``
            }
          }, 'logged in')
        } else {
          setIsFbRegister({
            fb_register: true,
            fb_access_token: accessToken,
            fb_verify_otp: false,
            fb_button: true,
            type: 'g'
          })
        }
      }).finally(() => {
        setGmailLoading(false)
      })
    }
    if (error) {
      setGmailLoading(false)
    }
  }
  return (
    <div className="login-wrapper">
      <img width="50%" src="/moha_logo.png" title="" alt="" />
      {!mobilenumber && !isFbRegister.fb_register && (
        <SendOTP setOtpStatus={setOtpStatus} mobilenumber={mobilenumber} />
      )}
      {mobilenumber && !isFbRegister.fb_register && (
        <VerifyOTP
          mobilenumber={mobilenumber}
          isRegister={isRegister}
          setOtpStatus={setOtpStatus}
          setIsFbRegister={setIsFbRegister}
          fb_verify_data={isFbRegister}
          close={isAuthenticated ? props.close : () => { }}
        />
      )}
      {isFbRegister.fb_register && (
        <FBRegisterOTP
          setIsFbRegister={setIsFbRegister}
          setOtpStatus={setOtpStatus}
          mobilenumber={mobilenumber}
          access_token={isFbRegister.fb_access_token}
          fb_verify_data={isFbRegister}
        />
      )}

      {/* // FB login Button */}
      <div className='social-login'>
        {!isFbRegister.fb_button && <div className='fb-button'>
          <div className="btn-facebook btn text-black"
            onClick={() => {
              setLoading(true)
              fbLogin().then(async (d: any) => {
                if (d.status) {
                  setToken(d.token);
                  reloadLogin();
                  setLoading(false)
                  const customerDetails = await graphqlWebClient.request(gql`{
                    ${customer}
                  }`);
                  we.userLogin({
                    email: `${customerDetails?.customer?.email}`,
                    phone: `${customerDetails?.customer?.mobilenumber}`,
                    first_name: `${customerDetails?.customer?.firstname}`,
                    last_name: `${customerDetails?.customer?.lastname}`,
                    user_id: `${customerDetails?.customer?.email}`,
                    mode: `login`,
                    modeSource: {
                      source: `FaceBook`,
                      page: ``
                    }
                  }, 'logged in')
                } else {
                  setIsFbRegister({
                    fb_register: true,
                    fb_access_token: d?.access_token,
                    fb_verify_otp: false,
                    fb_button: true,
                    type: 'fb'
                  })
                  setLoading(false)
                }
              }).catch((err) => {
                setLoading(false)
              }).finally(() => {
                setLoading(false)
              })
            }}
          >
            {loading ? <ButtonLoader color='#1877f2' /> : <>
              <img className="img-fluid sprite facebook-icon-1" src="/facebook.png" width="24" />
              <span>Continue with Facebook</span> </>
            }
          </div>

        </div>}

        {/* // Gmail login Button */}

        {!isFbRegister.fb_button && <div className='google-btn'>
          <div className="btn-gmail btn text-black"
            onClick={(e) => {
              setGmailLoading(true);
            }}
          >
            {gmailLoading ? <ButtonLoader color='#1877f2' /> : <>
              <div className='gmail-logo'>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
              </div>
              <GoogleLogin
                clientId="762121232540-mutt51bv1vf2dkk71ei5kq41q4l7fjhq.apps.googleusercontent.com"
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                isSignedIn={false}
                render={renderProps => (
                  <div onClick={renderProps.onClick}>Continue with Gmail</div>
                )}
                cookiePolicy={'single_host_origin'}
              />
            </>
            }
          </div>
        </div>}
      </div>
      <div>
      </div>
      <p className="tnc-clause">
        By Signing up you agree to our Terms &amp; Conditions
      </p>
      <div className='social-login-wrapper'>
      </div>
    </div>
  );
};
